
























































import { Vue, Component } from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

interface Video {
  title: string;
  youtubeId: string;
}

@Component({
  metaInfo: {
    title: "Nasıl Yapılır?"
  },
  components: {
    Breadcrumbs
  }
})
export default class Faq extends Vue {
  breadcrumbs = [
    {
      text: "Ana Sayfa",
      exact: true,
      to: { name: "home" }
    },
    {
      text: "Nasıl Yapılır?"
    }
  ];

  dialog = false;

  videos: Video[] = [
    {
      title: "Eğitimleri nasıl satın alabilirim?",
      youtubeId: "l_7SwkjG0Xc"
    },
    {
      title: "Satın aldığım eğitime nasıl giriş yapabilirim?",
      youtubeId: "EHHX4ZBo_Zw"
    },
    {
      title: "Eğitimin canlı ders kayıtlarını nasıl izleyebilirim?",
      youtubeId: "Z3mn-3sk1H4"
    }
  ];

  selectedVideo = {} as Video;

  get youtubeEmbedSrc(): string {
    return "https://www.youtube.com/embed/" + this.selectedVideo.youtubeId;
  }

  showVideo(v: Video): void {
    this.selectedVideo = v;
    this.dialog = true;
  }
}
